<template>
  <v-card>
    <v-container fluid>
      <v-data-iterator
        :items="species"
        :sort-by="sortByCamelCase"
        :sort-desc="sortDesc"
        hide-default-footer
        :items-per-page="100"
        no-data-text="No Species selected yet"
      >
        <template v-slot:header>
          <v-toolbar>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <AddSpeciesPopup
                v-show="!readonly"
                :materials="materials"
                :species="species"
                @addSpecies="addSpecies"
              />
              <v-tooltip bottom v-if="!readonly && !noFractions">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    class="info"
                    v-on="on"
                    @click="normalizeSpecies"
                  >
                    <v-icon>mdi-numeric-1-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span> Normalize to 100%</span>
              </v-tooltip>
              <v-spacer> </v-spacer>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-select
                  v-model="sortBy"
                  flat
                  hide-details
                  :items="sortByKeys"
                  label="Sort by"
                />
              </v-col>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn small depressed :value="false">
                  <v-icon>mdi-sort-descending</v-icon>
                </v-btn>
                <v-btn small depressed :value="true">
                  <v-icon>mdi-sort-ascending</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="(item, index) in props.items"
              :key="item.publicId"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <SpeciesCard
                v-model="props.items[index]"
                :sortBy="sortBy"
                :inputFraction="inputFraction"
                :inputFractions="inputFractions"
                :readonly="readonly"
                :noFractions="noFractions"
                :hint="hint"
                @deleteSpecies="deleteSpecies"
                @getMixture="getMixture"
                @changeInputFraction="changeInputFraction"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import AddSpeciesPopup from './AddSpeciesPopup';
import SpeciesCard from './SpeciesCard';

export default {
  name: 'SpeciesIterator',
  props: {
    species: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    noFractions: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    materials: { type: Array, required: true },
  },
  components: { SpeciesCard, AddSpeciesPopup },
  data() {
    return {
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 6,
      sortBy: 'Name',
      sortByKeys: ['Name', 'Formula', 'CAS', 'Input Fraction'],
      inputFraction: {
        name: 'Volume Fraction',
        unit: '%vol',
      },
    };
  },
  methods: {
    addSpecies(speciesToAdd) {
      this.$emit('addSpecies', speciesToAdd);
    },
    deleteSpecies(speciesToDelete) {
      this.$emit('deleteSpecies', speciesToDelete);
    },
    getMixture() {
      this.$emit('getMixture');
    },
    changeInputFraction(fraction) {
      var index = this.inputFractions.findIndex(
        item => item.name === fraction.name,
      );
      this.inputFraction = this.inputFractions[index];
    },
    normalizeSpecies() {
      // normalize depending on selected fraction input (mass, volume or mole fraction)
      var sum = 0;
      this.species.forEach(s => {
        sum += s[_.camelCase(this.inputFraction.name)];
      });
      if (sum > 0) {
        this.species.forEach(s => {
          s[_.camelCase(this.inputFraction.name)] /= sum;
        });
      }
      this.getMixture();
    },
  },
  computed: {
    ...mapGetters('mixtures', ['inputFractions']),
    numberOfPages() {
      return Math.ceil(this.species.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter(key => key !== `Name`);
    },
    sortByCamelCase() {
      return _.camelCase(this.sortBy);
    },
  },
  mounted() {},
};
</script>

<style></style>
