<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-on="on"
          :class="buttonClass"
          @click="open"
          depressed
          text
        >
          <v-icon :color="show ? iconColor : 'transparent'">
            {{ formIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ formTitle }}</span>
    </v-tooltip>

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      ref="dialog"
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title> {{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="updateMixture"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Save </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form class="px-3" ref="form">
            <v-expansion-panels v-model="panels" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>General</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Stream ID"
                        v-model="mixture.streamId"
                        required
                        :readonly="readonly"
                      />
                      <v-text-field
                        label="Name"
                        v-model="mixture.name"
                        :rules="nameRules"
                        :readonly="readonly"
                      />
                      <v-select
                        v-model="mixture.phase"
                        :items="phases"
                        label="Phase"
                        :readonly="readonly"
                      />
                      <v-select
                        v-model="mixture.streamType"
                        :items="types"
                        label="Type"
                        :readonly="readonly"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-if="
                          mixture.coupledToFuel && !couplingDisabled
                        "
                        v-model="mixture.coupledFuel"
                        :items="fuels"
                        label="Coupled Fuel"
                        item-text="name"
                        item-value="publicId"
                        return-object
                        required
                        @change="calcMassFlowFromLambda(mixture)"
                        :readonly="readonly"
                      >
                        <template v-slot:selection>
                          <!-- HTML that describe how select should render selected items -->
                          {{ coupledFuelDetails }}
                        </template>
                        <template v-slot:item="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.streamId }} |
                          {{ data.item.name }}
                        </template>
                      </v-select>
                      <v-numeric-text-field-unit
                        v-if="mixture.coupledToFuel"
                        v-model="mixture.lambda"
                        :settings="settings.lambda"
                        :required="mixture.coupledToFuel"
                        :readonly="readonly"
                        @change="calcMassFlowFromLambda(mixture)"
                      >
                        <template slot="label">
                          Combustion Air Ratio (&lambda;)
                        </template>
                      </v-numeric-text-field-unit>
                      <v-numeric-text-field
                        v-model="flowRate"
                        :label="settings.flowRate.label"
                        :suffix="settings.flowRate.unit"
                        :required="!mixture.coupledToFuel"
                        :disabled="
                          mixture.coupledToFuel ||
                            mixture.coupledToChamber
                        "
                        :readonly="readonly"
                        @change="getMixture"
                      >
                        <v-menu
                          slot="append"
                          transition="slide-y-transition"
                          bottom
                          :close-on-content-click="true"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="
                                settings.flowRate.units.length > 1
                              "
                              v-bind="attrs"
                              v-on="on"
                              class="switch-unit-button"
                            >
                              mdi-menu-down
                            </v-icon>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(unit, index) in settings
                                .flowRate.units"
                              :key="index"
                              @click="
                                changeFlowRateUnit(
                                  settings.flowRate,
                                  unit,
                                )
                              "
                            >
                              <v-list-item-title>{{
                                unit
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-numeric-text-field>
                      <!-- <v-numeric-text-field-unit
                        v-model="flowRate.value"
                        :settings="settings.flowRate"
                        :rho="mixture.rhoN"
                        :required="!mixture.coupledToFuel"
                        :disabled="mixture.coupledToFuel"
                        :readonly="readonly"
                        @change="getMixture"
                      /> -->
                      <v-numeric-text-field-unit
                        v-model="mixture.T"
                        :settings="settings.temperature"
                        :required="
                          !mixture.coupledToAmbientAirTemperature
                        "
                        :readonly="
                          mixture.coupledToAmbientAirTemperature ||
                            readonly
                        "
                        @change="getMixture"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-if="mixture.phase === 'g'"
                    >
                      <v-switch
                        v-model="mixture.coupledToChamber"
                        v-if="mixture.coupledToChamber"
                        :label="coupledChamberDetails"
                        disabled
                      ></v-switch>
                      <v-switch
                        v-model="mixture.coupledToFuel"
                        v-if="
                          mixture.streamType === 'Air' &&
                            !couplingDisabled
                        "
                        label="Couple to Fuel Stream"
                        :readonly="readonly"
                        :disabled="mixture.coupledToChamber"
                      ></v-switch>
                      <v-switch
                        v-model="
                          mixture.coupledToAmbientAirComposition
                        "
                        v-if="
                          mixture.streamType === 'Air' &&
                            !couplingDisabled
                        "
                        label="Couple to Ambient Air Composition"
                        @change="changeCompositionCoupling"
                        :readonly="readonly"
                      ></v-switch>
                      <v-switch
                        v-if="!couplingDisabled"
                        v-model="
                          mixture.coupledToAmbientAirTemperature
                        "
                        label="Couple to Ambient Temperature"
                        @change="changeTemperatureCoupling"
                        :readonly="readonly"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Species</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <MixtureFractionsGaugeChart
                      :mixtureFractions="mixtureFractions"
                      ref="mixtureFractionsGaugeChart"
                    />
                  </v-container>

                  <SpeciesIterator
                    :species="mixture.species"
                    :materials="materials"
                    :readonly="
                      mixture.coupledToAmbientAirComposition ||
                        readonly
                    "
                    :hint="hint"
                    @addSpecies="addSpecies"
                    @deleteSpecies="deleteSpecies"
                    @getMixture="getMixture"
                    ref="speciesIterator"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Summary</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <SpeciesTable :species="mixture.species" />
                  <v-container fluid>
                    <v-row justify="space-around">
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3">
                        <v-row justify="center">
                          <MixtureFractionsPieChart
                            :fractions="massFractions"
                            :showlegend="true"
                            title="Mass Fractions [%mass]"
                            ref="mixtureMassFractionsPieChart"
                          />
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3">
                        <v-row justify="center">
                          <MixtureFractionsPieChart
                            :fractions="volumeFractions"
                            :showlegend="true"
                            title="Volume Fractions [%vol]"
                            ref="mixtureVolumeFractionsPieChart"
                          />
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3">
                        <v-row justify="center">
                          <MixtureFractionsPieChart
                            :fractions="moleFractions"
                            :showlegend="true"
                            title="Mole Fractions [%mol]"
                            ref="mixtureMoleFractionsPieChart"
                          />
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3">
                        <v-row justify="center">
                          <ElementalMassFractionsPieChart
                            :elementalMassFractions="
                              mixture.elementalMassFractions
                            "
                            :showlegend="true"
                            title="Elemental Mass Fractions [%mass]"
                            ref="elementalMassFractionsPieChart"
                          />
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-numeric-text-field-unit
                          v-model="mixture.molarMass"
                          :settings="settings.molarMass"
                          :readonly="true"
                        />
                        <v-numeric-text-field-unit
                          v-model="mixture.rhoN"
                          :settings="settings.rho"
                          :disabled="autoDensity || readonly"
                        >
                          <v-icon
                            slot="prepend"
                            @click="changeAutoDensity"
                          >
                            {{ autoDensityIcon }}
                          </v-icon>
                        </v-numeric-text-field-unit>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-numeric-text-field-unit
                          v-model="mixture.CHRatio"
                          :settings="settings.CHRatio"
                          readonly
                        />
                        <v-numeric-text-field-unit
                          v-model="mixture.O2Demand"
                          :settings="settings.O2Demand"
                          readonly
                        >
                          <template slot="label">
                            <span v-html="htmlFormula('O2')"></span>
                            Demand
                          </template>
                          <template slot="append">
                            <span
                              >kg<sub
                                v-html="htmlFormula('O2')"
                              ></sub>
                              /kg<sub>fuel</sub>
                            </span>
                          </template>
                        </v-numeric-text-field-unit>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-numeric-text-field-unit
                          v-model="mixture.LHVBoie"
                          :settings="settings.LHVBoie"
                          :rho="mixture.rhoN"
                          readonly
                        />
                        <v-numeric-text-field-unit
                          v-model="mixture.LHVNist"
                          :settings="settings.LHVNist"
                          :rho="mixture.rhoN"
                          readonly
                        />
                        <v-numeric-text-field-unit
                          v-model="mixture.power"
                          :settings="settings.power"
                          readonly
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import SpeciesTable from './SpeciesTable';
import SpeciesIterator from './SpeciesIterator';
import MixtureFractionsGaugeChart from '@/components/charts/MixtureFractionsGaugeChart';
import MixtureFractionsPieChart from '@/components/charts/MixtureFractionsPieChart';
import ElementalMassFractionsPieChart from '@/components/charts/ElementalMassFractionsPieChart';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'MixturePopup',
  props: {
    inputMixture: { type: Object, required: true },
    mixtures: { type: Array, required: true },
    couplingDisabled: { type: Boolean, default: false },
    show: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
    buttonClass: { type: String, default: 'transparent' },
  },
  components: {
    SpeciesTable,
    SpeciesIterator,
    ElementalMassFractionsPieChart,
    MixtureFractionsPieChart,
    MixtureFractionsGaugeChart,
  },
  data() {
    return {
      dialog: false,
      mixture: JSON.parse(JSON.stringify(this.inputMixture)),
      nameRules: [v => (v && v.length >= 1) || 'Minimum length is 1'],
      phases: ['g', 'l', 's'],
      panels: [0, 1, 2],
      pNorm: 101325,
      TNorm: 273.15,
      mixtureMemory: JSON.parse(JSON.stringify(this.inputMixture)),
      autoDensity: true,
      settings: {
        temperature: {
          label: 'Temperature',
          type: UnitConverter.types.TEMPERATURE,
          min: 0,
          max: null,
          baseUnit: 'K',
          unit: '°C',
          precision: 2,
          units: ['K', '°C', '°F'],
          precisions: [2, 2, 2],
        },
        flowRate: {
          label: 'Flow Rate',
          type: UnitConverter.types.FLOWRATE,
          min: 0,
          max: null,
          baseUnit: 'kg/s',
          unit: 'Nm³/h',
          precision: 2,
          units: ['Nm³/h', 'kg/h'],
          precisions: [2, 2],
        },
        lambda: {
          label: 'Combustion Air Ratio (&lambda;)',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        molarMass: {
          label: 'Molar mass',
          type: UnitConverter.types.MOLARMASS,
          min: 0,
          max: null,
          baseUnit: 'kg/mol',
          unit: 'kg/kmol',
          precision: 3,
          units: ['kg/kmol'],
          precisions: [3],
        },
        rho: {
          label: 'Density',
          type: UnitConverter.types.DENSITY,
          min: 0,
          max: null,
          baseUnit: 'kg/Nm³',
          unit: 'kg/Nm³',
          precision: 10,
          units: ['kg/Nm³'],
          precisions: [3],
        },
        CHRatio: {
          label: 'C/H ratio',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        O2Demand: {
          label: 'O2 demand',
          type: UnitConverter.types.UNITLESS,
          min: null,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        LHVBoie: {
          label: 'LHV (Boie)',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/kg',
          unit: 'kJ/kg',
          precision: 0,
          units: [
            'kJ/kg',
            'MJ/kg',
            'kWh/kg',
            'kcal/kg',
            'kJ/Nm³',
            'MJ/Nm³',
            'kWh/Nm³',
            'kcal/Nm³',
          ],
          precisions: [0, 3, 2, 0, 0, 3, 2, 0],
        },
        LHVNist: {
          label: 'LHV (Nist)',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/kg',
          unit: 'kJ/kg',
          precision: 0,
          units: [
            'kJ/kg',
            'MJ/kg',
            'kWh/kg',
            'kcal/kg',
            'kJ/Nm³',
            'MJ/Nm³',
            'kWh/Nm³',
            'kcal/Nm³',
          ],
          precisions: [0, 3, 2, 0, 0, 3, 2, 0],
        },
        power: {
          label: 'Thermal Output',
          type: UnitConverter.types.POWER,
          min: null,
          max: null,
          baseUnit: 'W',
          unit: 'kW',
          precision: 2,
          units: ['W', 'kW', 'MW'],
          precisions: [0, 1, 3],
        },
      },
      fractionInput: 'Mass Fraction',
    };
  },
  methods: {
    validateForm() {
      return new Promise((resolve, reject) => {
        if (_.round(this.massFractionSum, 6) != 1.0) {
          reject({
            response: {
              data: {
                msg:
                  'Species fractions have to sum up to 100 %. Please check your inputs.',
              },
            },
          });
        } else if (!this.$refs.form.validate()) {
          reject({
            response: {
              data: {
                msg:
                  'Some inputs are missing or not correct, please check!',
              },
            },
          });
        } else {
          resolve({ data: { msg: 'Validation successful!' } });
        }
      });
    },
    updateMixture() {
      this.validateForm()
        .then(response => {
          if (this.mixture.publicId === -1) {
            this.mixture.publicId = uuidv4();
          }
          this.$emit('updateMixture', this.mixture);
        })
        .catch(error => {
          var snack;
          snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
          return;
        });
    },
    addSpecies(newSpecies) {
      newSpecies = JSON.parse(JSON.stringify(newSpecies));
      if (this.mixture.species.length === 0) {
        var inputFraction = _.camelCase(
          this.$refs.speciesIterator.inputFraction.name,
        );
        console.log(inputFraction);
        Array.isArray(newSpecies)
          ? newSpecies.forEach(
              s => (s[inputFraction] = 1.0 / newSpecies.length),
            )
          : (newSpecies[inputFraction] = 1.0);
      }
      Array.isArray(newSpecies)
        ? this.mixture.species.push(...newSpecies)
        : this.mixture.species.push(newSpecies);
      this.getMixture();
    },
    deleteSpecies(speciesToDelete) {
      const index = this.mixture.species.findIndex(
        s => s.publicId === speciesToDelete.publicId,
      );
      if (index !== -1) {
        this.mixture.species.splice(index, 1);
      }
      this.getMixture();
    },
    calcMassFlowFromLambda() {
      this.$store
        .dispatch('mixtures/calcMassFlowFromLambda', this.mixture)
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    changeTemperatureCoupling() {
      if (this.mixture.coupledToAmbientAirTemperature) {
        this.mixtureMemory.T = this.mixture.T;
        this.mixture.T = this.ambient.T;
      } else {
        this.mixture.T = this.mixtureMemory.T;
      }
    },
    changeCompositionCoupling() {
      if (this.mixture.coupledToAmbientAirComposition) {
        Object.assign(
          this.mixtureMemory.species,
          this.mixture.species,
        );
        this.mixture.species = this.$store.getters[
          'mixtures/ambientSpecies'
        ];
      } else {
        this.mixture.species = [];
        Object.assign(
          this.mixture.species,
          this.mixtureMemory.species,
        );
      }
      this.getMixture();
    },
    changeFlowRateUnit(settings, newUnit) {
      this.changeUnit(settings, newUnit);
      if (newUnit === 'Nm³/h') {
        this.mixture.flowRateInput = 'Volume Flow';
      } else {
        this.mixture.flowRateInput = 'Mass Flow';
      }
    },
    changeUnit(settings, newUnit) {
      var index = settings.units.findIndex(unit => unit === newUnit);
      settings.unit = newUnit;
      settings.precision = settings.precisions[index];
    },
    getMixture() {
      if (this.mixture.species.length > 0) {
        var mixture = this.mixture;
        var inputFraction = this.$refs.speciesIterator.inputFraction
          .name;

        this.$store
          .dispatch('mixtures/getMixture', {
            mixture,
            inputFraction,
          })
          .then(response => {
            var mix = response.data.mixture;
            mix.T = this.mixture.T;
            mix.p = this.mixture.p;
            if (mix.flowRateInput === 'Volume Flow') {
              mix.normVolumeFlow = this.mixture.normVolumeFlow;
              mix.massFlow = mix.normVolumeFlow * mix.rhoN;
            } else {
              // nothing to do in case of "Mass Flow"
            }
            this.mixture = Object.assign(this.mixture, mix);
            if (this.mixture.coupledToFuel) {
              this.calcMassFlowFromLambda();
            }
          })
          .catch(error => console.log(error));
      }
    },
    changeAutoDensity() {
      this.autoDensity = !this.autoDensity;
      if (this.autoDensity) {
        this.getMixture();
      }
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.mixture = JSON.parse(JSON.stringify(this.inputMixture));
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    open(e) {
      e.cancelBubble = true;
      this.dialog = true;
      this.mixture = JSON.parse(JSON.stringify(this.inputMixture));
      if (this.mixture.flowRateInput == 'Volume Flow') {
        this.settings.flowRate.unit = 'Nm³/h';
      } else {
        this.settings.flowRate.unit = 'kg/h';
      }
    },
  },
  computed: {
    ...mapGetters('materials', ['materials', 'htmlFormula']),
    ...mapGetters('mixtures', ['streamTypes', 'ambient']),
    formTitle() {
      if (this.readonly) {
        return 'Mixture Info';
      } else {
        if (this.mixture.publicId === -1) {
          return 'New Mixture';
        } else {
          return 'Edit Mixture';
        }
      }
    },
    formIcon() {
      if (this.readonly) {
        return 'mdi-information';
      } else {
        if (this.mixture.publicId === -1) {
          return 'mdi-plus';
        } else {
          return 'mdi-pencil';
        }
      }
    },
    autoDensityIcon() {
      return this.autoDensity ? 'mdi-lock' : 'mdi-lock-open';
    },
    hint() {
      var gasSpeciesPresent = this.mixture.species.some(
        s => s.phase === 'g',
      );
      var liquidSpeciesPresent = this.mixture.species.some(
        s => s.phase === 'l',
      );
      var solidSpeciesPresent = this.mixture.species.some(
        s => s.phase === 's',
      );
      if (
        gasSpeciesPresent &&
        (liquidSpeciesPresent || solidSpeciesPresent)
      ) {
        return `valid at norm conditions (p=${this.pNorm *
          1e-5}bar, T=${this.TNorm - 273.15}°C)`;
      } else {
        return '';
      }
    },
    normMixture() {
      var normMixture = JSON.parse(JSON.stringify(this.mixture));
      normMixture.T = this.TNorm;
      normMixture.p = this.pNorm;
      return normMixture;
    },
    massFractionSum() {
      return this.mixture.species.reduce(
        (pv, cv) => pv + cv.massFraction,
        0,
      );
    },
    fuels() {
      return this.mixtures.filter(mix => mix.streamType === 'Fuel');
    },
    fuelNames() {
      return this.fuels.map(
        fuel => fuel.streamId + ' | ' + fuel.name,
      );
    },
    filteredMaterials() {
      var filteredMaterials = JSON.parse(
        JSON.stringify(
          this.materials.filter(
            mat => mat.phase === this.mixture.phase,
          ),
        ),
      );
      filteredMaterials.forEach(mat => (mat.checked = true));
      return filteredMaterials;
    },
    types() {
      return this.streamTypes[this.mixture.phase].map(
        type => type.name,
      );
    },
    coupledFuelDetails() {
      var settings;
      var f = this.mixture.coupledFuel;
      settings = this.settings.flowRate;
      const massFlow = UnitConverter.convert(
        f.massFlow,
        settings.type,
        settings.baseUnit,
        settings.unit,
        settings.precision,
        f.rhoN,
      );
      settings = this.settings.LHVNist;
      const LHV = UnitConverter.convert(
        f.LHVNist,
        settings.type,
        settings.baseUnit,
        settings.unit,
        settings.precision,
        f.rhoN,
      );
      var details = `${f.streamId} | `;
      details += `${f.name}: `;
      details += `${massFlow} ${this.settings.flowRate.unit} @ `;
      details += `${LHV} ${this.settings.LHVBoie.unit}`;
      return details;
    },
    coupledChamberDetails() {
      var chambers = this.$store.getters[
        'combustionChambers/combustionChambers'
      ];
      var index = chambers.findIndex(
        chamber => chamber.publicId === this.mixture.coupledChamber,
      );
      return index === -1
        ? ''
        : `Used for Setpoint Control: ${chambers[index].name}`;
    },
    flowRate: {
      get: function() {
        var flowRate = 0.0;
        var settings = this.settings.flowRate;
        if (this.mixture.flowRateInput == 'Volume Flow') {
          flowRate = UnitConverter.convert(
            this.mixture.normVolumeFlow,
            settings.type,
            'Nm³/s',
            settings.unit,
            settings.precision,
            this.mixture.rhoN,
          );
        } else if (this.mixture.flowRateInput == 'Mass Flow') {
          flowRate = UnitConverter.convert(
            this.mixture.massFlow,
            settings.type,
            'kg/s',
            settings.unit,
            settings.precision,
            this.mixture.rhoN,
          );
        }
        return flowRate;
      },
      set: function(newValue) {
        var settings = this.settings.flowRate;
        if (!isNaN(parseFloat(newValue))) {
          this.mixture.normVolumeFlow = UnitConverter.convert(
            newValue,
            settings.type,
            settings.unit,
            'Nm³/s',
            null, //settings.precision,
            this.mixture.rhoN,
          );
          this.mixture.massFlow = UnitConverter.convert(
            newValue,
            settings.type,
            settings.unit,
            'kg/s',
            null, //settings.precision,
            this.mixture.rhoN,
          );
        }
      },
    },
    mixtureFractions() {
      var mixtureFractions = [];
      this.mixture.species.forEach(s => {
        mixtureFractions.push({
          formula: s.formula,
          fraction: s[_.camelCase(this.fractionInput)],
          phase: s.phase,
        });
      });
      return mixtureFractions;
    },
    massFractions() {
      return this.mixture.species.map(s => ({
        formula: s.formula,
        fraction: s.massFraction,
        phase: s.phase,
      }));
    },
    volumeFractions() {
      return this.mixture.species.map(s => ({
        formula: s.formula,
        fraction: s.volumeFraction,
        phase: s.phase,
      }));
    },
    moleFractions() {
      return this.mixture.species.map(s => ({
        formula: s.formula,
        fraction: s.moleFraction,
        phase: s.phase,
      }));
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    // this.reset();
  },
};
</script>

<style scoped>
.switch-unit-button {
  pointer-events: auto;
}
</style>
