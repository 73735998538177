<template>
  <div>
    <plotly v-bind="attr" :data="data" :layout="layout"> </plotly>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MixtureFractionsGaugeChart',
  components: {},
  props: {
    mixtureFractions: { type: Array, required: true },
    showlegend: { type: Boolean, default: true },
  },
  data() {
    return {
      data: [
        {
          domain: { x: [0, 1], y: [0, 1] },
          value: 50,
          // title: { text: 'Mass Fractions [%mass]' },
          type: 'indicator',
          mode: 'gauge+number+delta',
          delta: { reference: 100 },
          gauge: {
            shape: 'bullet',
            axis: { range: [null, 200] },
            steps: [
              { range: [0, 100], color: 'lightgray' },
              { range: [100, 200], color: 'gray' },
            ],
            threshold: {
              line: { color: 'green', width: 4 },
              thickness: 0.75,
              value: 100,
            },
          },
        },
      ],
      attr: { displayModeBar: false },
      layout: {
        // width: 800,
        height: 75,
        autosize: true,
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
        margin: { t: 0, b: 0, l: 0, r: 0 },
      },
    };
  },
  methods: {
    updateChart() {
      // var d3colors = plotly.d3.scale.category10();
      const opacity = '0.5';
      var d3colors = [
        'rgba(31,119,181,' + opacity + ')', // '#1f77b4', // muted blue
        'rgba(255,127,14,' + opacity + ')', // '#ff7f0e', // safety orange
        'rgba(44,160,44,' + opacity + ')', // '#2ca02c', // cooked asparagus green
        'rgba(214,39,40,' + opacity + ')', // '#d62728', // brick red
        'rgba(148,103,189,' + opacity + ')', // '#9467bd', // muted purple
        'rgba(140,86,75,' + opacity + ')', // '#8c564b', // chestnut brown
        'rgba(227,119,194,' + opacity + ')', // '#e377c2', // raspberry yogurt pink
        'rgba(127,127,127,' + opacity + ')', // '#7f7f7f', // middle gray
        'rgba(188,189,34,' + opacity + ')', // '#bcbd22', // curry yellow-green
        'rgba(23,190,207,' + opacity + ')', // '#17becf', // blue-teal
      ];
      var layout = JSON.parse(JSON.stringify(this.layout));
      var labels = this.mixtureFractions.map(item => item.formula);
      var values = this.mixtureFractions.map(
        item => item.fraction * 100,
      );
      var data = [];
      var trace = this.emptyTrace();

      var sum = _.round(
        values.reduce((pv, cv) => pv + cv, 0),
        6,
      );
      trace.value = sum;
      trace.gauge.axis.range[1] = Math.max(100, sum);
      var steps = [];
      var start = 0;
      var end = 0;
      var iColor = 0;
      for (var i = 0; i < labels.length; ++i) {
        i === 0 ? (start = 0) : (start = end);
        end = start + values[i];
        var step = {
          range: [start, end],
          color: d3colors[iColor],
          text: labels[i],
        };
        steps.push(step);
        iColor === d3colors.length - 1 ? (iColor = 0) : iColor++;
      }
      trace.gauge.steps = steps;
      trace.delta.reference = 2 * sum - 100;
      if (sum === 100) {
        // trace.gauge.bar.color = 'green';
        trace.delta.increasing.color = 'green';
        trace.gauge.threshold.line.color = 'green';
      }

      // assign local trace and layout objects back
      data.push(trace);
      this.data = data;
      this.layout = layout;
    },
    emptyTrace() {
      return {
        domain: { x: [0.05, 0.95], y: [0.3, 1.0] },
        value: 50,
        number: { suffix: '%', valueformat: '.#' },
        // title: { text: 'Mass Fractions [%mass]' },
        type: 'indicator',
        mode: 'gauge+number+delta',
        delta: {
          reference: 100,
          valueformat: '.3f',
          increasing: { color: 'red' },
          decreasing: { color: 'red' },
        },
        gauge: {
          shape: 'bullet',
          axis: {
            range: [null, 150],
          },
          bar: { color: 'transparent' },
          steps: [],
          threshold: {
            line: { color: 'red', width: 4 },
            thickness: 0.75,
            value: 100,
          },
        },
      };
    },
  },
  watch: {
    mixtureFractions: {
      handler(newVal) {
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateChart();
  },
};
</script>

<style></style>
