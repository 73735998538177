<template>
  <div>
    <plotly v-bind="attr" :data="data" :layout="layout"> </plotly>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MixtureFractionsPieChart',
  components: {},
  props: {
    fractions: { type: Array, required: true },
    minimal: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },
  data() {
    return {
      data: [
        {
          values: [],
          rotation: 0,
          textinfo: 'none',
          labels: [],
          hoverinfo: 'label+percent',
          hole: 0.5,
          type: 'pie',
        },
      ],
      attr: { displayModeBar: false },
      layout: {
        domain: { x: [0, 0.8], y: [0, 1] },
        title: {
          text: this.title,
          font: {
            // family: 'Courier New, monospace',
            size: 18,
            // color: 'white',
          },
          xref: 'paper',
          x: 0.5,
        },
        showlegend: !this.minimal,
        legend: {
          font: {
            // color: 'white'
          },
          xref: 'paper',
          x: 0.65,
        },
        autosize: true,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 80,
          pad: 0,
        },
        hovermode: true,
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
        height: 250,
        // width: 500,
      },
    };
  },
  methods: {
    updateChart() {
      var layout = JSON.parse(JSON.stringify(this.layout));
      var labels = this.fractions.map(
        item => `${this.htmlFormula(item.formula)}(${item.phase})`,
      );
      var values = this.fractions.map(item => item.fraction * 100);
      var data = [
        {
          values: values,
          rotation: 0,
          textinfo: 'label+percent',
          textposition: 'inside',
          // automargin: true,
          labels: labels,
          // hoverinfo: 'label+percent',
          hovertemplate:
            'Species: %{label} <br>' +
            '%{value:.2f}  %<br>' +
            '<extra></extra>',
          hole: 0.0,
          type: 'pie',
          showlegend: !this.minimal,
          sort: false,
        },
      ];
      // assign local trace and layout objects back
      this.data = data;
      this.layout = layout;
    },
  },
  computed: {
    ...mapGetters('materials', ['htmlFormula']),
  },
  watch: {
    fractions: {
      handler(newVal) {
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateChart();
  },
};
</script>

<style></style>
