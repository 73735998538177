<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" @click="open" class="success">
          <v-icon> {{ buttonIcon }} </v-icon>
        </v-btn>
      </template>
      <span>{{ buttonToolTip }}</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      v-if="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title>
            Add Inlet Stream
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="addMixture"
                v-on="on"
                :disabled="checked.length === 0"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> {{ saveButtonText }} </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <MixtureIterator
            :mixtures="mixtures"
            :addableMixtures="[]"
            :disabledMixtures="usedMixtures"
            :readonly="true"
            :deletable="false"
            :checked="checked"
            :iconColor="iconColor"
            @changeChecked="changeChecked"
            @disabledAction="disabledAction"
            ref="mixtureIterator"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MixtureIterator from './MixtureIterator';
export default {
  name: 'AddMixturePopup',
  props: {
    mixtures: { type: Array, required: true },
    usedMixtures: { type: Array, required: true },
    buttonToolTip: { type: String, default: 'Add Mixture' },
    buttonIcon: { type: String, default: 'mdi-plus' },
    iconColor: { type: String, default: 'white' },
    disabledActionText: {
      type: String,
      default: 'Selection not allowed',
    },
  },
  components: { MixtureIterator },
  data() {
    return {
      dialog: false,
      checked: [],
    };
  },
  methods: {
    changeChecked(publicId) {
      const index = this.checked.findIndex(el => el === publicId);
      if (index === -1) {
        this.checked.push(publicId);
      } else {
        this.checked = this.checked.filter(e => e != publicId);
      }
    },
    disabledAction() {
      var snack = {
        text: this.disabledActionText,
        color: 'warning',
      };
      this.$store.dispatch('misc/setSnack', snack);
    },
    addMixture() {
      var mixturesToAdd = this.checkedMixtures;
      this.$emit('addMixture', mixturesToAdd);
      this.reset();
      this.close();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.checked = [];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    checkedMixtures() {
      var checkedMixtures = [];
      this.checked.forEach(publicId => {
        var mixture = this.mixtures.find(
          mix => mix.publicId === publicId,
        );
        checkedMixtures.push(mixture);
      });
      return checkedMixtures;
    },
    saveButtonText() {
      return `Add Selected Mixtures (${this.checked.length})`;
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style></style>
