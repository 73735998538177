<template>
  <div>
    <v-container fluid class="my-5">
      <DatabaseMixtureIterator :iconColor="iconColor">
      </DatabaseMixtureIterator>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DatabaseMixtureIterator from '@/components/mixtures/DatabaseMixtureIterator';

export default {
  name: 'MixtureDatabase',
  components: { DatabaseMixtureIterator },
  computed: {
    ...mapGetters(['iconColor']),
  },
  methods: {
    addMixture() {
      var mix = this.$store.getters['mixtures/emptyMixture']();
      this.$store
        .dispatch('mixtures/addDatabaseMixture', mix)
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.close();
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
