<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" @click="dialog = true" class="success">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>Add Species to Mixture</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      v-if="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title> Add Species to Mixture</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="addSpecies"
                v-on="on"
                :disabled="checkedMaterials.length === 0"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> {{ saveButtonText }} </span>
          </v-tooltip>
        </v-toolbar>
        <MaterialIterator
          :materials="materials"
          :species="species"
          :readonly="true"
          :checked="checked"
          @checkAllMaterials="checkAllMaterials"
          @changeChecked="changeChecked"
          @disabledAction="disabledAction"
          ref="materialIterator"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MaterialIterator from '@/components/materials/MaterialIterator';
export default {
  name: 'AddSpeciesPopup',
  props: {
    materials: { type: Array, required: true },
    species: { type: Array, required: true },
  },
  components: { MaterialIterator },
  data() {
    return {
      loading: false,
      dialog: false,
      checked: [],
    };
  },
  methods: {
    checkAllMaterials() {
      var zeroChecked = this.checked.length === 0;
      this.checked = []; // empty checked array in any case
      if (zeroChecked) {
        // check all species except for species that are already in the mixture
        this.materials.forEach(mat => {
          if (!this.species.some(s => s.publicId === mat.publicId)) {
            this.checked.push(mat.publicId);
          }
        });
      }
    },
    changeChecked(publicId) {
      const index = this.checked.findIndex(el => el === publicId);
      if (index === -1) {
        this.checked.push(publicId);
      } else {
        this.checked = this.checked.filter(e => e != publicId);
      }
    },
    disabledAction(msg = 'Species cannot be checked') {
      var snack = {
        text: msg,
        color: 'warning',
      };
      this.$store.dispatch('misc/setSnack', snack);
    },
    addSpecies() {
      var species = [];
      this.checkedMaterials.forEach(mat => {
        var s = this.$store.getters['materials/emptySpecies']();

        Object.keys(mat)
          .filter(key => key in s)
          .forEach(key => (s[key] = mat[key]));
        species.push(s);
      });
      this.$emit('addSpecies', species);
      this.reset();
      this.close();
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.checked = [];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    saveButtonText() {
      return `Add Selected Species (${this.checked.length})`;
    },
    checkedMaterials() {
      var checkedMaterials = [];
      this.checked.forEach(publicId => {
        var material = this.materials.find(
          mix => mix.publicId === publicId,
        );
        checkedMaterials.push(material);
      });
      return checkedMaterials;
    },
  },
  mounted() {},
};
</script>

<style></style>
