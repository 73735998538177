<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 20 : 2"
      :class="{ 'on-hover': hover, warning: material === null }"
    >
      <v-overlay
        v-if="material === null"
        absolute
        :value="overlay"
        @click.native="overlay = false"
      >
        <div style="text-align: center;">
          <span>
            Species not found in material database!
          </span>
        </div>
      </v-overlay>
      <v-card-title class="subheading font-weight-bold">
        {{ species.name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list dense color="transparent">
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              Formula:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              <span v-html="htmlFormula(species.formula)"></span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'CAS' }"
            >
              CAS:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'CAS' }"
            >
              {{ species.cas }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Phase' }"
            >
              Phase:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Phase' }"
            >
              {{ species.phase }}
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Molar Mass' }"
            >
              Molar Mass:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Molar Mass' }"
            >
              {{ species.molarMass * 1000 }} kg/kmol
            </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-numeric-text-field
          v-model="mixtureFraction"
          :label="inputFraction.name"
          :suffix="inputFraction.unit"
          v-if="!noFractions"
          :readonly="readonly"
          @change="getMixture"
          :precision="null"
          :class="{
            'sort-by-style-input': sortBy === 'Input Fraction',
            'sort-by-style': sortBy === 'Input Fraction',
          }"
          :messages="
            inputFraction.name !== 'Mass Fraction' ? hint : ''
          "
          :persistent-hint="true"
        >
          <span
            slot="message"
            v-if="inputFraction.name !== 'Mass Fraction'"
            class="red--text"
          >
            {{ hint }}
          </span>
          <v-menu
            slot="append"
            transition="slide-y-transition"
            bottom
            close-on-content-click
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="inputFractions.length > 1"
                v-bind="attrs"
                v-on="on"
                class="switch-unit-button"
              >
                mdi-menu-down
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(fraction, index) in inputFractions"
                :key="index"
                @click="changeInputFraction(fraction)"
              >
                <v-list-item-title>{{
                  fraction.unit
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-numeric-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <MaterialPopup
          v-if="material !== null"
          :inputMaterial="material"
          :show="hover"
          :readonly="true"
          :iconColor="iconColor"
        />
        <v-spacer v-if="!readonly"></v-spacer>
        <v-tooltip bottom v-if="!readonly">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              text
              v-on="on"
              color="transparent"
              @click="deleteSpecies"
            >
              <v-icon :color="hover ? iconColor : 'transparent'">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span> Remove </span>
        </v-tooltip>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';
import MaterialPopup from '@/components/materials/MaterialPopup';

export default {
  name: 'SpeciesCard',
  props: {
    value: { type: Object, required: true },
    sortBy: { type: String },
    inputFraction: { type: Object, required: true },
    inputFractions: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    noFractions: { type: Boolean, default: false },
    hint: { type: String, default: '' },
  },
  components: { MaterialPopup },
  data() {
    return {
      overlay: true,
      settings: {
        dhS: {
          label: 'Enthalpy of Solution',
          varName: 'dhS',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/kg',
          unit: 'kJ/kg',
          precision: 2,
          units: ['J/kg', 'kJ/kg'],
          precisions: [2, 2],
        },
      },
    };
  },
  methods: {
    deleteSpecies() {
      this.$emit('deleteSpecies', this.species);
    },
    changeInputFraction(inputFraction) {
      this.$emit('changeInputFraction', inputFraction);
    },
    getMixture() {
      this.$emit('getMixture');
    },
  },
  computed: {
    ...mapGetters('materials', ['htmlFormula']),
    ...mapGetters(['iconColor']),
    species: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit('update', newValue);
      },
    },
    mixtureFraction: {
      get: function() {
        return this.species[_.camelCase(this.inputFraction.name)] ===
          null
          ? ''
          : _.round(
              this.species[_.camelCase(this.inputFraction.name)] *
                100,
              4,
            );
      },
      set: function(newValue) {
        if (!isNaN(parseFloat(newValue))) {
          this.species[_.camelCase(this.inputFraction.name)] =
            parseFloat(newValue) / 100;
        }
      },
    },
    dhS: {
      get: function() {
        const settings = this.settings.dhS;
        return UnitConverter.convert(
          this.species[settings.varName],
          settings.type,
          settings.baseUnit,
          settings.unit,
          settings.precision,
          this.mixture.rhoN,
        );
      },
      set: function(newValue) {
        if (!isNaN(parseFloat(newValue))) {
          const settings = this.settings.dhS;
          this.species[settings.varName] = UnitConverter.convert(
            parseFloat(newValue),
            settings.type,
            settings.unit,
            settings.baseUnit,
            null,
            this.mixture.rhoN,
          );
          this.mixtureMemory[settings.varName] = this.mixture[
            settings.varName
          ];
        }
      },
    },
    material() {
      var materials = this.$store.getters['materials/materials'];
      var index = materials.findIndex(
        mat => mat.publicId === this.species.publicId,
      );
      return index === -1 ? null : materials[index];
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}

.sort-by-style-input >>> .v-text-field__slot input {
  /* color: #2196f3; */
  color: yellowgreen;
}

.sort-by-style {
  /* color: #2196f3; */
  color: yellowgreen;
}
.switch-unit-button {
  pointer-events: auto;
}
</style>
