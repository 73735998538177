<template>
  <v-card>
    <v-container fluid>
      <v-data-iterator
        :items="mixtures"
        :sort-by="sortByCamelCase"
        :sort-desc="sortDesc"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    class="info mx-1"
                    v-on="on"
                    @click="refresh"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip> -->
              <v-spacer> </v-spacer>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                ></v-text-field>
              </v-col>
              <v-spacer> </v-spacer>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-select
                  v-model="sortBy"
                  flat
                  hide-details
                  :items="sortByKeys"
                  label="Sort by"
                />
              </v-col>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn small depressed :value="false">
                  <v-icon>mdi-sort-descending</v-icon>
                </v-btn>
                <v-btn small depressed :value="true">
                  <v-icon>mdi-sort-ascending</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.publicId"
              cols="12"
              xs="12"
              sm="6"
              md="4"
              lg="2"
            >
              <MixtureCard
                :mixture="item"
                :mixtures="mixtures"
                :sortBy="sortBy"
                :readonly="readonly"
                :disabled="
                  disabledMixtures.some(
                    s => s.publicId === item.publicId,
                  )
                "
                :deletable="deletable"
                :checked="checked.some(e => e === item.publicId)"
                :iconColor="iconColor"
                @updateMixture="updateMixture"
                @duplicateMixture="duplicateMixture"
                @deleteMixture="deleteMixture"
                @changeChecked="changeChecked"
                @disabledAction="disabledAction"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row class="mt-1" align="center" justify="center">
            <span>Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text class="ml-1" v-on="on">
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span class="mr-4">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn fab small class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab small class="ml-1 mr-2" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import MixtureCard from '@/components/mixtures/MixtureCard';

export default {
  name: 'MixtureIterator',
  props: {
    mixtures: { type: Array, required: true },
    disabledMixtures: { type: Array, required: true },
    addableMixtures: { type: Array, required: true },
    checked: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    deletable: { type: Boolean, default: true },
    iconColor: { type: String, default: 'white' },
  },
  components: { MixtureCard },
  data() {
    return {
      itemsPerPageArray: [6, 12, 24],
      search: '',
      filter: {},
      page: 1,
      itemsPerPage: 12,
      sortDesc: false,
      sortBy: 'Stream ID',
      sortByKeys: [
        'Stream ID',
        'Name',
        'Phase',
        'Stream Type',
        'Mass Flow',
        'Temperature',
      ],
    };
  },
  methods: {
    addMixture(mixture) {
      this.$emit('addMixture', mixture);
    },
    updateMixture(mixture) {
      this.$emit('updateMixture', mixture);
    },
    duplicateMixture(mixture) {
      this.$emit('duplicateMixture', mixture);
    },
    deleteMixture(mixture) {
      this.$emit('deleteMixture', mixture);
    },
    changeChecked(publicId) {
      this.$emit('changeChecked', publicId);
    },
    disabledAction() {
      this.$emit('disabledAction');
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
  computed: {
    ...mapGetters('mixtures', ['emptyMixture']),
    numberOfPages() {
      return Math.ceil(this.mixtures.length / this.itemsPerPage);
    },
    sortByCamelCase() {
      return _.camelCase(this.sortBy);
    },
  },
  mounted() {},
};
</script>

<style></style>
