<template>
  <v-data-table
    :headers="headers"
    :items="species"
    sort-by="name"
    class="elevation-1"
    hide-default-footer
    :items-per-page="100"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-row>
        <MaterialPopup
          :inputMaterial="item"
          :show="true"
          :readonly="true"
        />
      </v-row>
    </template>
    <template v-slot:[`item.formula`]="{ item }">
      <span v-html="htmlFormula(item.formula)"></span>
    </template>
    <template v-slot:[`item.molarMass`]="{ item }">
      {{ round(item.molarMass * 1000, 3) }}
    </template>
    <template v-slot:[`item.massFraction`]="{ item }">
      {{ round(item.massFraction * 100, 3) }}
    </template>
    <template v-slot:[`item.moleFraction`]="{ item }">
      {{ round(item.moleFraction * 100, 3) }}
    </template>
    <template v-slot:[`item.volumeFraction`]="{ item }">
      {{ round(item.volumeFraction * 100, 3) }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import MaterialPopup from '@/components/materials/MaterialPopup';
export default {
  name: 'SpeciesTable',
  components: { MaterialPopup },
  props: {
    species: { type: Array, required: true },
  },
  data() {
    return {
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Formula', value: 'formula' },
        { text: 'CAS', value: 'cas' },
        { text: 'Molar Mass [kg/kmol]', value: 'molarMass' },
        { text: 'Mass Fraction [%mass]', value: 'massFraction' },
        { text: 'Volume Fraction [%vol]', value: 'volumeFraction' },
        { text: 'Mole Fraction [%mol]', value: 'moleFraction' },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    round(value, precision) {
      return _.round(value, precision);
    },
  },
  computed: {
    ...mapGetters('materials', ['htmlFormula']),
  },
  mounted() {
    // this.refresh();
  },
};
</script>

<style></style>
