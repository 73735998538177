<template>
  <v-card>
    <v-container fluid>
      <v-data-iterator
        :items="mixtures"
        :sort-by="sortByCamelCase"
        :sort-desc="sortDesc"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    class="info mx-1"
                    v-on="on"
                    @click="refresh"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
              <MixturePopup
                class="mx-1"
                :inputMixture="emptyMixture()"
                :mixtures="mixtures"
                :readonly="false"
                :show="true"
                buttonClass="success"
                @updateMixture="addDatabaseMixture"
                ref="addDatabaseMixturePopup"
              />
              <AddMixturePopup
                class="mx-1"
                :mixtures="projectMixtures"
                :usedMixtures="usedMixtures"
                buttonToolTip="Add mixture from current project to database"
                buttonIcon="mdi-flask-plus"
                :iconColor="iconColor"
                disabledActionText="Mixture with the same name already in Database"
                @addMixture="addDatabaseMixture"
                ref="addProjectMixturePopup"
              />
              <v-spacer> </v-spacer>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                ></v-text-field>
              </v-col>
              <v-spacer> </v-spacer>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-select
                  v-model="sortBy"
                  flat
                  hide-details
                  :items="sortByKeys"
                  label="Sort by"
                />
              </v-col>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn small depressed :value="false">
                  <v-icon>mdi-sort-descending</v-icon>
                </v-btn>
                <v-btn small depressed :value="true">
                  <v-icon>mdi-sort-ascending</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.publicId"
              cols="12"
              xs="12"
              sm="6"
              md="4"
              lg="3"
            >
              <MixtureCard
                :mixture="item"
                :mixtures="[]"
                :sortBy="sortBy"
                :readonly="false"
                :disabled="true"
                :couplingDisabled="true"
                :iconColor="iconColor"
                @updateMixture="updateDatabaseMixture"
                @duplicateMixture="duplicateDatabaseMixture"
                @deleteMixture="deleteDatabaseMixture"
                :ref="item.publicId"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row class="mt-1" align="center" justify="center">
            <span>Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text class="ml-1" v-on="on">
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span class="mr-4">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn fab small class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab small class="ml-1 mr-2" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import MixtureCard from '@/components/mixtures/MixtureCard';
import MixturePopup from '@/components/mixtures/MixturePopup';
import AddMixturePopup from '@/components/mixtures/AddMixturePopup';

export default {
  name: 'DatabaseMixtureIterator',
  props: {
    iconColor: { type: String, default: 'white' },
  },
  components: { MixtureCard, MixturePopup, AddMixturePopup },
  data() {
    return {
      itemsPerPageArray: [6, 12, 24],
      search: '',
      filter: {},
      page: 1,
      itemsPerPage: 12,
      sortDesc: false,
      sortBy: 'Name',
      sortByKeys: [
        'Stream ID',
        'Name',
        'Phase',
        'Stream Type',
        'Mass Flow',
        'Temperature',
      ],
    };
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    addDatabaseMixture(mixture) {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('mixtures/addDatabaseMixture', { mixture })
        .then(response => {
          this.$store.dispatch('misc/setLoading', false);
          this.$store.dispatch('misc/setSnack', {
            text: response.data.msg,
            color: 'success',
          });
          if (this.$refs.addDatabaseMixturePopup.dialog) {
            this.$refs.addDatabaseMixturePopup.close();
          }
          if (this.$refs.addProjectMixturePopup.dialog) {
            this.$refs.addProjectMixturePopup.close();
          }
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    updateDatabaseMixture(mixture) {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('mixtures/updateDatabaseMixture', mixture)
        .then(response => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.$refs[mixture.publicId][0].$refs.mixturePopup.close();
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    duplicateDatabaseMixture(mixture) {
      this.$store
        .dispatch('mixtures/duplicateDatabaseMixture', mixture)
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    deleteDatabaseMixture(mixtureToDelete) {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch(
          'mixtures/deleteDatabaseMixture',
          mixtureToDelete.publicId,
        )
        .then(response => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    refresh() {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('mixtures/getDatabaseMixtures')
        .then(response => {
          var snack = {
            text: 'Mixtures successfully fetched from Database!',
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.$store.dispatch('misc/setLoading', false);
        })
        .catch(error => {
          var snack = {
            text: 'Error fetching Mixtures from Database!',
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.$store.dispatch('misc/setLoading', false);
        });
    },
  },
  computed: {
    ...mapGetters('mixtures', {
      mixtures: 'databaseMixtures',
      projectMixtures: 'mixtures',
    }),
    ...mapGetters('mixtures', ['emptyMixture']),
    numberOfPages() {
      return Math.ceil(this.mixtures.length / this.itemsPerPage);
    },
    sortByCamelCase() {
      return _.camelCase(this.sortBy);
    },
    usedMixtures() {
      var dbMixtureNames = this.mixtures.map(mix => mix.name);
      return this.projectMixtures.filter(mix =>
        dbMixtureNames.includes(mix.name),
      );
    },
  },
  mounted() {},
};
</script>

<style></style>
