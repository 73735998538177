<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 20 : 2"
      :class="{
        info: checked,
        'on-hover': hover,
        warning: !allSpeciesInMaterialDatabase,
      }"
      @click="changeChecked"
      :ripple="!disabled"
    >
      <v-overlay
        v-if="!allSpeciesInMaterialDatabase"
        absolute
        :value="overlay"
        @click.native="overlay = false"
      >
        <div style="text-align: center;">
          <span>
            Some species in mixture not found in material database!
          </span>
        </div>
      </v-overlay>
      <v-card-title class="subheading font-weight-bold">
        <span :class="{ 'sort-by-style': sortBy === 'Stream ID' }">
          {{ mixture.streamId }} &nbsp;
        </span>
        <span style="padding-left:4px"> | </span>
        <span
          style="padding-left:4px"
          :class="{ 'sort-by-style': sortBy === 'Name' }"
        >
          {{ mixture.name }}
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list dense color="transparent">
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Phase' }"
            >
              Phase:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Phase' }"
            >
              {{ mixture.phase }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Stream Type' }"
            >
              Stream Type:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Stream Type' }"
            >
              {{ mixture.streamType }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Mass Flow' }"
            >
              Flow Rate:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Mass Flow' }"
            >
              {{ flowRate }} {{ flowRateUnit }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="mixture.coupledToFuel">
            <v-list-item-content>
              &lambda;:
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ mixture.lambda }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Temperature' }"
            >
              Temperature:
            </v-list-item-content>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Temperature' }"
            >
              {{ temperature }} °C
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Temperature' }"
            >
              O2 Demand:
            </v-list-item-content>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Temperature' }"
            >
              {{ O2Demand }}
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-container fluid>
                <MixtureFractionsPieChart
                  :minimal="true"
                  :fractions="massFractions"
                  ref="mixtureFractionsPieChart"
                />
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <MixturePopup
          :readonly="readonly"
          :inputMixture="mixture"
          :mixtures="mixtures"
          :couplingDisabled="couplingDisabled"
          :show="hover"
          :iconColor="iconColor"
          @updateMixture="updateMixture"
          ref="mixturePopup"
        />
        <v-spacer v-if="!readonly"></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-if="!readonly"
              small
              depressed
              text
              color="transparent"
              @click="duplicateMixture"
            >
              <v-icon :color="hover ? iconColor : 'transparent'">
                mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          <span> Duplicate </span>
        </v-tooltip>
        <v-spacer v-if="deletable"></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-if="deletable"
              small
              depressed
              text
              color="transparent"
              @click="deleteMixture"
            >
              <v-icon :color="hover ? iconColor : 'transparent'">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span> Delete </span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
// import { mapActions } from 'vuex';
import _ from 'lodash';
import MixturePopup from './MixturePopup';
import MixtureFractionsPieChart from '@/components/charts/MixtureFractionsPieChart';

export default {
  name: 'MixtureCard',
  props: {
    mixture: { type: Object, required: true },
    mixtures: { type: Array, required: true },
    sortBy: { type: String },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    couplingDisabled: { type: Boolean, default: false },
    deletable: { type: Boolean, default: true },
    checked: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
  },
  components: { MixturePopup, MixtureFractionsPieChart },
  data() {
    return {
      overlay: true,
    };
  },
  methods: {
    updateMixture(mixture) {
      this.$emit('updateMixture', mixture);
    },
    duplicateMixture() {
      this.$emit('duplicateMixture', this.mixture);
    },
    deleteMixture() {
      this.$emit('deleteMixture', this.mixture);
    },
    changeChecked() {
      if (this.disabled) {
        this.$emit('disabledAction');
      } else {
        this.$emit('changeChecked', this.mixture.publicId);
      }
    },
  },
  computed: {
    molarMass() {
      return this.mixture.molarMass === null
        ? ''
        : _.round(this.mixture.molarMass * 1000, 2);
    },
    flowRate() {
      return this.mixture.flowRateInput === 'Volume Flow'
        ? _.round(this.mixture.normVolumeFlow * 3600, 2)
        : _.round(this.mixture.massFlow * 3600, 2);
    },
    flowRateUnit() {
      return this.mixture.flowRateInput === 'Volume Flow'
        ? 'Nm³/h'
        : 'kg/h';
    },
    temperature() {
      return this.mixture.T === null
        ? ''
        : _.round(this.mixture.T - 273.15, 2);
    },
    O2Demand() {
      return this.mixture.O2Demand === null
        ? ''
        : _.round(this.mixture.O2Demand, 3);
    },
    massFractions() {
      var massFractions = this.mixture.species.map(s => ({
        formula: s.formula,
        fraction: s.massFraction,
        phase: s.phase,
      }));
      return massFractions;
    },
    allSpeciesInMaterialDatabase() {
      var materials = this.$store.getters['materials/materials'];
      var allSpeciesFound = this.mixture.species.every(s =>
        materials.some(mat => mat.publicId === s.publicId),
      );
      return allSpeciesFound;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}

.sort-by-style-input >>> .v-text-field__slot input {
  color: yellowgreen;
}

.sort-by-style {
  color: yellowgreen;
}
</style>
